.header {
    height: 50px;
    border-bottom: 1px solid #eaedf3;
    background: #fff;
}
.line {
    border-right: 1px solid #eee;
}
.loading {
    margin: auto;
    margin-top: 35vh;
    text-align: center;
}

.welcome {
    height: 100px;
    box-shadow: 0 2px 2px #0000000d;
    background: #fff;
}
.welcome-sm {
    height: 70px;
    box-shadow: 0 2px 2px #0000000d;
    background: #fff;
}
._panel {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    box-shadow: 0 1px 3px #0000000a;
}
.botm {
    border-bottom: 1px solid #eaedf3;
}
.topm {
    border-top: 1px solid #eaedf3;
}
._darc {
    background-color: #fbfbfd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
._top_darc {
    background-color: #fbfbfd;
}
._botm {
    border-bottom: 1px solid #eaedf3;
}
.etype {
    border: 1px solid #eeeeee;
    border-radius: 4px;
}
.progress-container {
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background-color: #fbfbfd;
}
.MuiLinearProgress-barColorPrimary {
    background-color: #03ca98 !important;
    border-radius: 2px;
}
._full_screen {
    height: 100vh;
}
._fixed {
    top: 0;
    width: 100%;
    z-index: 444;
}
._menu_drop {
    width: 230px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    box-shadow: 0 1px 3px #0000000a;
    background-color: #fff;
    right: 0.3rem;
}

._admin_drop {
    width: 300px;
    border-radius: 4px !important;
    border: 1px solid #eaedf3;
    box-shadow: 0 1px 3px #0000000a;
    background-color: #fff;
    right: 2rem;
}
._hover {
    background-color: #fff
}
._hover:Hover {
    background: '#e6492d !important';
}
._bolx {
    border: 1px solid #e2e5ed;
    overflow: hidden;
    border-radius: 4px;
    padding-left: 10px;
    margin-top: -1px;
}
._loc_input {
    height: 38px;
    border: none;
}
._loc_input:focus {
    outline: 0;
}
._btn_clear {
    border: none;
    background: none;
}
._lists:hover {
    background-color: #fbfbfd;
}
.no_card {
    margin-top: 10px;
    background-color: #fff;
    right: 0;
    width: 400px;
    border: 1px solid #eaedf3;
    border-radius: 4px;
    box-shadow: 0 1px 3px #0000000a;
}
.no_card_sm {
    margin-top: 10px;
    background-color: #fff;
    right: 2vw;
    width: 96vw;
    border: 1px solid #eaedf3;
    border-radius: 4px;
    box-shadow: 0 1px 3px #0000000a;
}
._danger_b {
    background-color: #e6492d;
    padding: 4px 6px;
    border-radius: 4px;
}
._notification_area {
    height: 80vh;
    overflow-x: scroll;
}
._unseen{
    background-color: #F6F9FF;
}
