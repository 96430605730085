@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #fbfbfd;
}
._h-100 {
  height: 92vh;
}
.w-100 {
  width: 100%;
}
.w-95 {
  width: 95%;
}
.w-90 {
  width: 90%;
}
.w-85 {
  width: 85%;
}
.w-82 {
  width: 82%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-68 {
  width: 68%;
}
.w-60 {
  width: 60%;
}
.w-57 {
  width: 57%;
}
.w-55 {
  width: 55%;
}
.w-50 {
  width: 50%;
}
.w-48 {
  width: 48%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-35 {
  width: 35%;
}
.w-38 {
  width: 38%;
}
.w-30 {
  width: 30%;
}
.w-23 {
  width: 23%;
}
.w-20 {
  width: 20%;
}
.w-18 {
  width: 18%;
}
.w-10 {
  width: 8%;
}
.w-15 {
  width: 13%;
}

.background {
  background-color: #fbfbfd;
  height: 100vh;
}

.overview_background {
  background-color: #fbfbfd;
}

.app-box {
  background-color: #fff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 6px #eeeeee;
}
.b-r {
  border-right: 2px solid #f2f2f5 !important;
}
.b-t {
  border-top: 2px solid #f2f2f5;
}
.img-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-image: url("https://images.unsplash.com/photo-1600870187251-b55f9c574638?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80");
  background-size: cover;
}
.pointer:hover {
  cursor: pointer;
}
.boxx {
  height: 20px !important;
}
.boxy {
  border-radius: 4px;
  box-shadow: 0 1px 6px #d2d2d2a1;
}
.custom-styles {
  --ReactInputVerificationCode-itemWidth: 15.2%;
  --ReactInputVerificationCode-itemHeight: 2.5rem;
}
.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #9ea0a5;
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
.custom-styles .ReactInputVerificationCode__item:hover {
  cursor: auto;
}
.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
  border: 1px solid #e2e5ed;
}
.custom-styles .ReactInputVerificationCode__item.is-active {
  border-color: #03ca98;
}
._bg {
  background-color: #fbfbfd;
  height: 100vh;
  overflow: hidden;
}
.fixed-header {
  position: fixed;
  width: 100%;
  z-index: 888;
}
.free-space {
  height: 150px;
}
.ovContainer {
  height: 100vh;
  overflow: scroll;
}
._textarea {
  border: 1px solid #e2e5ed;
  border-radius: 4px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  color: #3e3f42;
  font-family: "Roboto";
  min-height: 127px;
  max-height: 130px;
  overflow-x: hidden;
}
._textarea:focus {
  outline: 0;
}
.react-responsive-modal-modal {
  padding: 0 !important;
}
.drop-area {
  border-radius: 4px;
  background-color: #fafafa;
  border: 1px dashed #e2e5ed;
}
.player-container {
  overflow: hidden;
  border-radius: 4px;
  background-color: #000;
}
._separate {
  border-bottom: #eaedf3 1px solid;
  height: 1;
}
.over-lay {
  background: transparent linear-gradient(180deg, #00000000 0%, #0000005c 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 4px 4px 0px;
  opacity: 1;
}
a {
  text-decoration: none;
}
.drop-search {
  z-index: 999;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  margin-top: 5px;
  box-shadow: 0 1px 2px #00000014;
}
.ld {
  margin-bottom: 10px;
}
.pac-target-input {
  width: 100%;
  height: 36px;
  font-size: 15px;
  font-family: "Roboto";
  color: #3e3f42;
  border: none;
}
.pac-target-input:focus {
  outline: 0 !important;
}
.pac-target-input::placeholder {
  color: #9ea0a5;
}
.react-tel-input .flag-dropdown {
  background-color: #fff !important;
  border-top: #d8dce6 1px solid !important;
  border-bottom: #d8dce6 1px solid !important;
  border-left: #d8dce6 1px solid !important;
  border-right: none !important;
  border-radius: 4px 0 0 4px !important;
}
.react-tel-input .country-list {
  width: 17.4rem !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #d8dce6;
  box-shadow: 0 1px 2px #00000014;
}

.react-tel-input .country-list .country.highlight {
  background-color: #fff !important;
}
.m-box {
  height: 35px;
  margin-bottom: 5px;
}
._botk {
  background-color: #03ca98;
  border-radius: 4px;
}
._btu {
  border: none;
  width: 2rem;
  border-radius: 4px;
  background-color: #fff;
}
._btu:focus {
  outline: 0;
}
._jumbo {
  height: 272px;
}
._jumbo_cover {
  box-shadow: 0 1px 3px #0000000d;
  background-color: #fff;
}
._bg_color {
  background-color: #fbfbfd;
  height: 100%;
}
._marker {
  height: 24px;
  border-radius: 4px;
  border: 1px solid #d8dce6;
  padding: 0 7px;
  padding-bottom: -3px !important;
}
._j_container {
  border-radius: 4px;
  border: 1px solid #eaedf3;
  box-shadow: 0 1px 3px #0000000a;
}
._topbar {
  height: 60px;
  background-color: #fff;
  box-shadow: 0 1px 3px #0000000d;
}
.btn {
  padding: 0 !important;
  background: "red";
}
.btn:hover {
  background: "red";
}
.btn:focus {
  outline: 0;
  background: "none";
}

._gbtn {
  height: 36px;
  background: none;
  border: none;
}
button:focus {
  outline: 0;
}
._gbtn_container {
  background-color: #fff;
  height: 38px;
  border: 1px solid #d8dce6;
  box-shadow: 0 1px 2px #00000014;
  border-radius: 4px;
}
._lin {
  display: inline-block;
  height: 30px;
  width: 1px;
  background-color: #eaedf3;
}
._job_list_card {
  height: 90px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #eaedf3;
  margin-bottom: 1rem;
}
._sep {
  width: 1px;
  background-color: #eaedf3;
  height: 50px;
}
._full_h {
  height: 80vh;
}
._top_bottom_border {
  border-top: 1px solid #e2e5ed;
  border-bottom: 1px solid #e2e5ed;
  height: 42px;
  margin-top: -2px;
}
._search_x {
  border: 1px solid #d8dce6;
  border-radius: 4px;
  padding: 0 10px;
}
._card_v {
  top: 100%;
}
._marker_ {
  width: 0px;
  height: 100px;
  display: inline-block;
  position: relative;
  margin-top: -220px;
}
._j_card {
  position: absolute;
  margin-left: -100px !important;
  top: -220px;
}
._dow {
  box-shadow: 0 1px 6px #888787;
  background-color: #fff;
}
._sep_ {
  height: 1px;
  background-color: #eaedf3;
}
._alert_card {
  height: 231px;
  width: 240px;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px #0000000a;
}
._job_cover {
  height: 255px;
  border-radius: 4px 4px 0 0;
  position: relative;
  overflow: hidden;
}
._job_cover_sm {
  height: 168px;
  border-radius: 4px 4px 0 0;
  position: relative;
  overflow: hidden;
}
._back_btn {
  border: none;
  border-radius: 4px;
  background-color: #3e3f4277;
  width: 36px !important;
  height: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1rem;
  left: 1rem;
}
._clogo {
  height: 46px;
  width: 46px;
  border-radius: 4px;
}
._ccover {
  width: 100%;
}
._bl {
  border-left: 1px solid #eaedf3;
}
._companybox {
  border: 1px solid #eaedf3;
  border-radius: 4px;
  box-shadow: 0 1px 3px #0000000a;
}
.btn_p {
  width: 50%;
  height: 49px;
  border: none;
  background-color: #fbfbfd;
}
._br {
  border-left: 1px solid #eaedf3;
}

._bs {
  box-shadow: 0 1px 3px #0000000a;
  border: 1px solid #eaedf3;
}
._iconcircle {
  display: inline-block;
  height: 38px;
  width: 38px;
  border-radius: 50px;
  border: 1px solid #e2e5ed;
}
._status_header {
  background-color: #fff;
  height: 50px;
  box-shadow: 0 1px 3px #0000000d;
}
.MuiTab-wrapper {
  width: auto !important;
}

.MuiTab-root {
  min-width: auto !important;
  max-width: auto !important;
  font-weight: normal !important;
}
._statetag {
  display: inline-block;
  height: 24px;
  border-radius: 4px;
  padding: 0 10px;
}
.PROCCESSING {
  background-color: #facf55;
}
.OFFER {
  background-color: #57ab45;
}
.INTERVIEW {
  background-color: #3e3f43;
}
.UNSUCCESSFUL {
  background-color: #e64932;
}
.bt-r {
  border-top: #eaedf3 1px solid;
}
._sep2 {
  background-color: #e2e5ed;
  width: 1px;
  height: 45px;
}
.CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  stroke: gray;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
}
._topright {
  top: 0;
  right: 0;
}
.app_panel {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #eaedf3;
  box-shadow: 0 1px 3px #0000000a;
}
.chat_header {
  height: 50px;
  background-color: #fff;
}
.chats_box {
  border-right: 1px solid #eaedf3;
}
.btm_ {
  bottom: 0;
  width: 52.5%;
  position: fixed;
}
._h55 {
  min-height: 50px;
}
._boz {
  box-shadow: 0 -1px 0 #f1f1f3;
  background-color: #fff;
}
._inp {
  border: 1px solid #eaedf3;
  resize: none;
  border-radius: 2px;
}

._inp:focus {
  outline: 0;
}

._inp::-webkit-scrollbar {
  width: 5px;
}

/* Track */
._inp::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
._inp::-webkit-scrollbar-thumb {
  background-color: #d8dce6;
}

._myMsg {
  background-color: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px 0 4px 4px;
  display: inline-block;
  max-width: 50%;
  margin-top: 0.4rem;
}
._themMsg {
  background-color: #f6f5fe;
  border: 1px solid #eaedf3;
  border-radius: 0 4px 4px 4px;
  display: inline-block;
  max-width: 50%;
  margin-top: 0.4rem;
}
._csel {
  background-color: #f6f5fe;
}
._cdes {
  background-color: #fff;
}
.ltm {
  border-left: 1px solid #eaedf3;
}
._sbut {
  position: absolute;
  top: 0.3rem;
  right: 0rem;
}

._scr {
  overflow-y: scroll;
  height: 80vh;
}
._scr::-webkit-scrollbar {
  width: 5px;
}

/* Track */
._scr::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
._scr::-webkit-scrollbar-thumb {
  background-color: #d8dce6;
}

._cactive {
  display: inline-block;
  border-bottom: 2px solid #03ca98;
  height: 100%;
}
._mobi-line {
  overflow-x: scroll;
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 9;
  left: 0;
  top: 120px;
  box-shadow: 0 1px 3px #0000000d;
}
.tabtop {
  top: 150px;
}
.mobi_w {
  justify-content: space-around;
  max-width: 150px;
  min-width: 160px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
._lbr_border {
  border-bottom: 1px solid #d8dce6;
  border-left: 1px solid #d8dce6;
  border-right: 1px solid #d8dce6;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-radius: 0px 0px 4px 4px;
  margin-bottom: 1rem;
}
._sm_jumbo {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
}
._searcc {
  border: #e2e5ed 1px solid;
  padding: 8px 0px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: baseline;
  position: relative;
}
._seIcon {
  position: absolute;
  right: 1rem;
}
._str {
  width: 50vw;
}
._str_sm {
  width: 70vw;
}
._loco {
  justify-content: space-between;
}
._sele_ {
  background-color: #03ca980a;
}
._ws {
  display: inline-block;
  width: 2vw;
  justify-content: center;
  margin-left: 20px;
}
._emp_logo {
  width: 52px;
  height: 52px;
  background-color: #FFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d8dce6;
}
._emp_logo_sm {
  width: 36px;
  height: 36px;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
._opt:hover {
  background-color: #f5f5f5;
}
._flx_r {
  width: 23%;
}
._flx_r2 {
  width: 31%;
}
._flx_r4 {
  width: 47%;
}
._flx_r5 {
  width: 30%;
}
._flx_r6 {
  width: 48%;
}
._cx {
  grid-column-gap: 2%;
  column-gap: 2%;
}
.pac-container {
  z-index: 5000;
}
.stepper_container {
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
  width: 200px;
}
.step_count {
  width: 32px !important;
  height: 32px !important;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eaedf3;
  margin-right: 1rem;
}
._left_a {
  background-color: transparent;
  color: #3e3f42;
}
._complete {
  background-color: #03ca98;
  color: #fff;
}
._active {
  background-color: #fff;
  color: #3e3f42;
  box-shadow: 0 1px 3px #0000000a;
}
._left_l {
  color: #bcbcbc;
}
._month {
  display: inline-block;
  width: 25%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
._month:hover {
  background-color: #03ca98;
  cursor: pointer;
}
._month_sel {
  background-color: #03ca98;
}
._date_pi {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid #d8dce6;
  height: 39px;
  box-shadow: 0 1px 2px #00000014;
  font-family: Roboto;
  border-radius: 4px;
  justify-content: safe;
  align-items: center;
}
._dp {
  border: none;
  padding-left: 1rem;
  width: 92%;
  height: 100%;
}
._dp:focus {
  outline: 0;
}
._ddis {
  opacity: 0;
}
._topbar_sm {
  background-color: #fff;
  position: fixed;
  z-index: 999;
  box-shadow: 0 1px 3px #0000000d;
}
._gb {
  background-color: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  box-shadow: 0 1px 3px #0000000a;
}
._fiter_card {
  width: 240px;
  background-color: #252529;
  border-radius: 4px;
}
.PrivateValueLabel-circle-38 {
  width: 32px;
  height: 32px;
  display: block !important;
  transform: rotate(0deg) !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}
.PrivateValueLabel-circle-758 {
  width: 32px;
  height: 32px;
  display: block !important;
  transform: rotate(0deg) !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}
._droper {
  background-color: #fff;
  border: 1px solid #d8dce6;
  box-shadow: 0 1px 2px #00000014;
  border-radius: 4px;
  margin-top: 0.2rem;
  z-index: 999;
  right: 0;
  width: 100%;
}
._drop_item {
  padding: 0.3rem 1rem;
  cursor: pointer;
}
._drop_item:hover {
  background-color: #fbfbfd;
}
.gbtn {
  border: none;
  background: none;
  width: 50%;
  height: 51px;
}
._fiter_card_sm {
  background-color: #252529;
  overflow-x: scroll;
  max-height: 85vh;
}
.c4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
}
.c3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
}
.c2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
}
.grid_gap {
  grid-gap: 1rem;
  gap: 1rem;
}
.makeStyles-modal-35 {
  outline: none;
}
._header_img {
  background: #fafafa;
  height: 240px;
  width: 100%;
  border: 2px dashed #d8dce6;
  border-radius: 2px;
}
.header_image {
  height: 240px;
  width: 100%;
  border-radius: 4px;
}

._logo_img {
  background: #fafafa;
  height: 100px;
  width: 100px;
  border: 2px dashed #d8dce6;
  border-radius: 2px;
}
._jobOverview {
  border: 1px solid #eaedf3;
  background: #fff;
  border-left: #1665d8 3px solid;
  border-radius: 0 4px 4px 0;
  box-shadow: 0 1px 3px #0000000a;
}

.avatar {
  height: "33px";
  width: "33px";
  margin-right: "10px";
}

.button_right {
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #d8dce6;
  border-left: none;
  width: 90px;
}

.button_left {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #d8dce6;
  width: 90px;
}
._search {
  border: 1px solid #d8dce6;
}
._create_job {
  border-top: none;
}

._create_job_footer {
  border: 1px solid #eaedf3;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
  background-color: #fbfbfd;
}

.mapbox_search {
  background: #fff;
  border: 1px solid #d8dce6;
  max-height: 39px;
  width: 100%;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b7b7b7;
}
.mapbox_search:focus {
  outline: 0;
}

._drop {
  background: #fff;
  border: 1px solid #d8dce6;
  box-shadow: 0 1px 2px #00000014;
  border-radius: 4px;
  margin-top: 0.2rem;
  position: absolute;
  z-index: 999;
  max-height: 240px;
  min-height: 30px;
  overflow: auto;
}
.company_logo {
  height: 100px;
  width: 100px;
}
.card_style {
  border: 1px solid #eaedf3;
  background-color: #fff;
  box-shadow: 0 1px 3px #0000000a;
  border-radius: 4px;
}
._two_divs {
  display: table;
  width: 100%;
}

._editor {
  background: white;
  border: 1px solid #d8dce6;
  border-radius: 4px 4px 0px 0px;
  height: "auto";
  min-height: 200px;
}

._tool_bar {
  background: white;
  border: 1px solid #d8dce6;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

._invert_icon {
  transform: scaleX(-1);
}
.shadow_ {
  box-shadow: 0 1px 3px #0000000d;
}
.MuiBackdrop-root {
  opacity: 0 !important;
}

.srollbar-y {
  overflow-y: auto;
}

.srollbar-y::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.srollbar-y::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.srollbar-y::-webkit-scrollbar-thumb {
  background-color: #d8dce6;
}
._box {
  border-radius: 4px;
  box-shadow: 0 2px 8px #0000000D;
}

._jobList {
  border: 1px solid #03CA98;
  border-radius: 4px;
}

._jobListExternal:hover {
  background: #e8e8e8;
}
.app-text{
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #3E3F42;
    display: inline-block;
}
.txt:hover {
    text-decoration: underline;
    color: #018f6b;
}
._small{
    font-weight: 400;
}
._medium{
    font-weight: 500;
}
._bold{
    font-weight: 600;
}
._30{
    font-size: 30px;
}
._26{
    font-size: 26px;
}
._22{
    font-size: 22px;
}
._20{
    font-size: 20px;
}
._18{
    font-size: 18px;
}
._16{
    font-size: 16px;
}
._14{
    font-size: 14px;
}
._12{
    font-size: 12px;
}
._11{
    font-size: 11px;
}
._10{
    font-size: 10px;
}
._c100 {
    color: #3E3F42;
}
._c90{
    color: #6B6C6F
}
._c50{
    color: #9EA0A5
}
._c25{
    color: #a0a2a6;
}
.c10 {
    color: #eeeeee
}
._c0{
    color: #FFFFFF;
}
._g90{
    color: #03CA98;
}
._r100{
    color: #E6492D;
}
._g100{
    color: #34AA44!important;
}
._o100{
    color: #F6AB2F!important;
}
._center{
    text-align: center;
}
._justify{
    text-align: justify;
}
._left{
    text-align: left;
}


/* cirle */
.circle{
    max-width: 30px;
    max-height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 30px;
}
.still{
    border: 1px solid #EAEDF3;
}
.inProgress{
    background-color: #fff;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px #0000000A;
}
.done{
    background-color: #03CA98;
    border: 1px solid #fff;
    box-shadow: 0 1px 3px #0000000A;
}
._school_icon{
    height: 50px;
    width: 50px;
    margin-right: 15px;
    border: 1px solid #D8DCE6;
    border-radius: 4px;
}
.react-tel-input{
    height: 38px!important;
}
.react-tel-input input{
    height: 38px!important;
    color: #3E3F42!important;
    font-family: 'Roboto', sans-serif;
    border: 1px solid #D8DCE6;
}
.react-tel-input .form-control{
    font-family: 'Roboto', sans-serif;
    border: 1px solid #D8DCE6;
}
.react-tel-input .flag-dropdown{
    background-color: #fff;
    border: 1px solid #D8DCE6;
    border-radius: 4px 0 0 4px;
}
.react-tel-input .form-control:focus{
    box-shadow: none;
}
._chip{
    background-color: #FBFBFD;
    margin-right: 12px;
    border-radius: 50px;
    border: 1px solid #EAEDF3;
    padding: 2px 10px;
}
._cardskeleton{
    width: 255px;
    height: 210px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #EAEDF3;
    margin-bottom: 22px;
}

._cardskeleton_sm{
    width: 100%;
    height: 210px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #EAEDF3;
    margin-bottom: 22px;
}

._recipients {
    background: #FBFBFD;
    border-top: 2px solid #f2f2f5;
    border-bottom: 2px solid #f2f2f5;
}

.header {
    height: 50px;
    border-bottom: 1px solid #eaedf3;
    background: #fff;
}
.line {
    border-right: 1px solid #eee;
}
.loading {
    margin: auto;
    margin-top: 35vh;
    text-align: center;
}

.welcome {
    height: 100px;
    box-shadow: 0 2px 2px #0000000d;
    background: #fff;
}
.welcome-sm {
    height: 70px;
    box-shadow: 0 2px 2px #0000000d;
    background: #fff;
}
._panel {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    box-shadow: 0 1px 3px #0000000a;
}
.botm {
    border-bottom: 1px solid #eaedf3;
}
.topm {
    border-top: 1px solid #eaedf3;
}
._darc {
    background-color: #fbfbfd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
._top_darc {
    background-color: #fbfbfd;
}
._botm {
    border-bottom: 1px solid #eaedf3;
}
.etype {
    border: 1px solid #eeeeee;
    border-radius: 4px;
}
.progress-container {
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background-color: #fbfbfd;
}
.MuiLinearProgress-barColorPrimary {
    background-color: #03ca98 !important;
    border-radius: 2px;
}
._full_screen {
    height: 100vh;
}
._fixed {
    top: 0;
    width: 100%;
    z-index: 444;
}
._menu_drop {
    width: 230px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    box-shadow: 0 1px 3px #0000000a;
    background-color: #fff;
    right: 0.3rem;
}

._admin_drop {
    width: 300px;
    border-radius: 4px !important;
    border: 1px solid #eaedf3;
    box-shadow: 0 1px 3px #0000000a;
    background-color: #fff;
    right: 2rem;
}
._hover {
    background-color: #fff
}
._hover:Hover {
    background: '#e6492d !important';
}
._bolx {
    border: 1px solid #e2e5ed;
    overflow: hidden;
    border-radius: 4px;
    padding-left: 10px;
    margin-top: -1px;
}
._loc_input {
    height: 38px;
    border: none;
}
._loc_input:focus {
    outline: 0;
}
._btn_clear {
    border: none;
    background: none;
}
._lists:hover {
    background-color: #fbfbfd;
}
.no_card {
    margin-top: 10px;
    background-color: #fff;
    right: 0;
    width: 400px;
    border: 1px solid #eaedf3;
    border-radius: 4px;
    box-shadow: 0 1px 3px #0000000a;
}
.no_card_sm {
    margin-top: 10px;
    background-color: #fff;
    right: 2vw;
    width: 96vw;
    border: 1px solid #eaedf3;
    border-radius: 4px;
    box-shadow: 0 1px 3px #0000000a;
}
._danger_b {
    background-color: #e6492d;
    padding: 4px 6px;
    border-radius: 4px;
}
._notification_area {
    height: 80vh;
    overflow-x: scroll;
}
._unseen{
    background-color: #F6F9FF;
}

